import { Toaster } from 'react-hot-toast';
import textlogo from '../img/etern_footer_logo.png';
import feature from '../img/egg_thumbnail.jpg';
import utilityVideoOne from '../videos/section_video.mp4';
import quantaumBullet from '../img/quantum.png';
// eslint-disable-next-line
import logo from '../img/logo.png';
// eslint-disable-next-line
import companyLogo from '../img/eternmeta.png';
import MintPage from "../components/MintPage";
import Utility from './Utility';
import CommonHeader from './CommonHeader';
import NavHeader from './NavHeader';
import MintWorld from './MintWorld';

const Home = () => {

    return (
        <div className='homebody-kps'>
            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    className: '',
                    duration: 5000,
                    style: {
                        background: '#363636',
                        color: '#fff',
                        maxWidth: 600,
                        marginTop: 26
                    },
                    success: {
                        duration: 3000,
                        theme: {
                            primary: 'green',
                            secondary: 'black',
                        },
                    },
                }}
            />
            <NavHeader />
            <CommonHeader />
            <div className="main-landing-outter-page">
                <div className="main-landing-inner-page">
                    <div className="main-center-topic-conatiner">
                        {/* <div className='company-logo'>
                        <img src={companyLogo} alt="" />
                    </div>
                    <h2 className="main-h-two-topic">Mint</h2>
                    <p className="main-descriptioon-topic">Quickly design web layouts, and get HTML and CSS code. Learn CSS Grid
                        visually and build web layouts with our interactive CSS Grid Generator.</p> */}
                    </div>
                    <section className="right-side-conatiner">
                            <div className='promoBox-header'>
                                <h2 className='promo-box-title'>Eternalz Council</h2>
                                <div className='sub-box-title'> <div className='live-dot'></div> LIVE MINT</div>
                            </div>
                        </section>
                    <div id="MintSection" className="side-by-side-main-conatiner half-half-section mobile-colum">
                        {/* <div className="left-side-conatiner">
                            <div className="image-conatiner">
                                <video className='main-mint-video-kps' poster={feature} muted loop autoPlay playsInline>
                                    <source src={utilityVideoOne} type="video/mp4" />
                                </video>
                            </div>
                            <div className="kcp-right-side-container">
                                <p>Eternalz Council:</p>
                                <h2>Members Only Rewardz</h2>
                                <div className="right-box-container">
                                    <ul>
                                        <li>
                                            <img src={quantaumBullet} alt="" />
                                            <span>Transform your NFT into a masterpiece; a life-like 4D Eternal
                                                Avatar living in the Etern Metaverse with Web3 and Web2
                                                Utility.</span>
                                        </li>
                                        <li>
                                            <img src={quantaumBullet} alt="" />
                                            <span>Claim free 4000 Sqft of Etern Metaverse Landz.</span>
                                        </li>
                                        <li>
                                            <img src={quantaumBullet} alt="" />
                                            <span>Unlock VIP OG and Land Lord Status in the Eternalz Council
                                                representing your NFT collection.</span>
                                        </li>
                                        <li>
                                            <img src={quantaumBullet} alt="" />
                                            <span>Access to an exclusive OG Network, a closed group of Metaverse
                                                investors, developers, founders and creators.</span>
                                        </li>
                                        <li>
                                            <img src={quantaumBullet} alt="" />
                                            <span>Unlock a pool of Metaverse Dev Creditz and get access to exclusive
                                                discounts on resources</span>
                                        </li>
                                        <li>
                                            <img src={quantaumBullet} alt="" />
                                            <span> <span className="tag">Grand Prize</span> : Stand a chance to win Dev
                                                Creditz of USD 100,000 to launch your own Multiplayer Metaverse
                                                powered by Etern.</span>
                                        </li>
                                        <li>
                                            <span>(Subject to strict eligibility criteria and selection based on the
                                                pitch.)</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}

                        <MintWorld />
                        <MintPage />
                    </div>
                    {/* <Utility /> */}
                </div>
            </div>

            <footer>
                <div className="main-footer-conatiner">
                    <div className="main-footer-outter-conatiner">
                        <div className="main-footer-inner-conatiner">
                            <div className="footer-items">
                                <img className="footer-logo" src={textlogo} alt="" />
                            </div>
                            <div className='footer-items'>
                                <p className='company-details'>
                                    Etern Metaverse © 2023. All rights reserved.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
export default Home;