import React from 'react';
import '../App.scss';



function Tos() {
    return (
        <div className='tos-container'>
            <div className='tos-header-container'>
                <h2>Eternalz Council NFT Mint <br /> Terms of Service</h2>
            </div>
            <div className='tos-body-container'>
                <p>
                    This <b>Eternalz Council NFT Mint - Terms of Service</b> (“Agreement”) is a legally binding
                    agreement between <b>DejaVu Quest, Inc.</b> (hereinafter referred to as <b>“Company”</b>, <b>“EternMeta”</b>
                    <b>“Etern”</b>) and <b>Purchaser</b> of Eternals council NFT (hereinafter referred to as <b>“you”</b>,
                    <b>“Purchaser”</b>). EternMeta and the Purchaser are each referred to herein as a “Party” and
                    collectively as the <b>“Parties”</b>.
                </p>
                <p>
                    The following terms govern your participation in the EternMeta Eternalz Council NFT Mint and
                    the subsequent access to further services, utility benefits and resources in relation to the Etern
                    Metaverse. By accessing and participating in the EternMeta Eternalz Council NFT Mint, you
                    agree to be bound by these terms. If you do not agree to these terms, please refrain from
                    participating in the minting process.
                </p>
                <ul className='section-header-kps'>
                    <b>
                        <li>
                            <p>1. Definitions</p>
                        </li>
                    </b>
                </ul>
                <ul className='body-header-kps'>
                    <li>
                        <p>
                            Etern Metaverse: Etern Metaverse is a web3 ecosystem developed by DejaVu Quest,
                            Inc. It is the first metaverse to bring together a diverse community of NFT holders on
                            a single platform, transforming their NFTs into life-like 4D Avatarz inside an
                            immersively multiplayer metaverse experience.
                        </p>
                    </li>
                    <li>
                        <p>
                            EternMeta Eternalz Council NFT Mint: A Metaverse collective by Etern Metaverse
                            limited to 288 NFTs, each granting the purchaser OG membership that doubles as an
                            access passport to the Etern Metaverse and it’s Web3, Web2 and real world utility.
                        </p>
                    </li>
                    <li>
                        <p>
                            Avatarz: 4D virtual characters that embody the digital identity of the platform’s users.
                        </p>
                    </li>
                    <li>
                        <p>
                            Landz: The main building blocks of real estate in the Etern Metaverse. Landz are the
                            basis for constructing a variety of virtual world -“Worldz” within the Etern Metaverse.
                        </p>
                    </li>
                    <li>
                        <p>
                            Dev Creditz: Landlordz or Playerz who are eligible for Dev Creditz (Development
                            Creditz) receive discounts on resources to develop on Etern Metaverse Landz.
                            Discounts are provided by Metaverse development partners of Etern Metaverse,
                            including DejaVu Quest, Inc.
                        </p>
                    </li>
                </ul>
                <div>
                    <ul className='section-header-kps'>
                        <b>
                            <li>
                                <p>2. Benefits of the Eternalz Council NFTs</p>
                            </li>
                        </b>
                    </ul>
                    <p>The EternMeta Eternalz Council NFT Mint allows you to mint/purchase from a limited collection of 288 Eternalz Council NFTs.</p>
                    <p>Each Eternal Council NFT minted gives the purchaser access to the following benefits, rewards and resources:</p>
                    <ul>
                        <ol>
                            <li>
                                <p>Each Eternal Council NFT minted gives the purchaser access to the following benefits,
                                    rewards and resources:</p>
                            </li>
                            <li>
                                <p>Receive OG Landlord status in the Eternalz Council representing your NFT collection.
                                </p>
                            </li>
                            <li>
                                <p>Receive OG Landlord status in the Eternalz Council representing your NFT collection.
                                </p>
                            </li>
                            <li>
                                <p>Get access to the Etern Meta exclusive OG network consisting of Metaverse investors,
                                    developers, founders and creators.</p>
                            </li>
                            <li>
                                <p>Claim custom resources to develop Worldz within the Etern Metaverse including Dev
                                    Creditz and rewards.</p>
                            </li>
                            <li>
                                <p>Stand a chance to win USD 100,000 worth of non-cash Dev Creditz to launch your
                                    own 44,000 sqft multiplayer metaverse powered by Etern. <i>Note that only 1 out of the
                                        288 Eternalz Council NFT holders will be selected for this award. The competition will
                                        begin once all 288 OG members are officially onboarded. Etern undertakes careful
                                        consideration and assessment of candidates to be a part of the 288 exclusive Eternalz
                                        Council and the most deserving candidate to be selected for the Grand Prize</i>.</p>
                            </li>
                        </ol>
                    </ul>
                </div>

                <div>
                    <ul className='section-header-kps'>
                        <b>
                            <li>
                                <p>3. Payment, Fees and Purchase</p>
                            </li>
                        </b>
                    </ul>
                    <p>
                        Purchaser hereby agrees to purchase Eternalz Council NFT at the purchase price specified
                        on the transaction page of the website (<a href='https://eternmeta.com/'>https://eternmeta.com/</a>).
                    </p>
                    <p>You, the Purchaser hereby represents and warrants the following:</p>
                    <ul className='body-header-kps'>
                        <li>
                            <p>
                                You are above 18 years of age.
                            </p>
                        </li>
                        <li>
                            <p>
                                You have an Ethereum-compatible wallet address and wallet that you own and control.
                            </p>
                        </li>
                        <li>
                            <p>
                                You will pay the purchase price as indicated on the transaction page of the website
                                plus any gas and/or 3rd party transaction fee. Etern Meta does not have any control
                                over gas and/or 3rd party transaction fees, nor does Etern have the ability to reverse
                                any transactions. Accordingly, Etern will have no liability to you or any 3rd party for any
                                claims or damages that may result from any such transactions.
                            </p>
                        </li>
                        <li>
                            <p>
                                You are not located in a country that is subject to a U.S. Government embargo, or that
                                has been designated by the U.S. Government as a terrorist-supporting country.

                            </p>
                        </li>
                        <li>
                            <p>
                                You must not be listed on any U.S. Government list of prohibited or restricted parties.
                            </p>
                        </li>
                        <li>
                            <p>
                                You will not use this NFT or your purchase of this NFT to violate any law.
                            </p>
                        </li>
                        <li>
                            <p>
                                That you will comply with all laws in connection with your purchase, receipt of, use of
                                the NFT.
                            </p>
                        </li>
                    </ul>
                    <p>If you fail to meet any of the conditions above, Etern may suspend delivery of the NFT or
                        terminate the transaction.</p>
                    <p>If you fail to meet any of the conditions above, Etern may suspend delivery of the NFT or
                        terminate the transaction.</p>
                </div>

                <div>
                    <ul className='section-header-kps'>
                        <b>
                            <li>
                                <p>4. General Terms</p>
                            </li>
                        </b>
                    </ul>
                    <ul>
                        <ol>
                            <li>
                                <p>If you fail to meet any of the conditions above, Etern may suspend delivery of the NFT or
                                    terminate the transaction.</p>
                            </li>
                            <li>
                                <p>You acknowledge that you are purchasing this Eternalz Council NFT for your own
                                    personal enjoyment without any expectation of profit, increase in monetary or other
                                    value, nor access to any of Etern’s technology, platforms or features.</p>
                            </li>
                            <li>
                                <p>You acknowledge that you are purchasing this Eternalz Council NFT for your own
                                    personal enjoyment without any expectation of profit, increase in monetary or other
                                    value, nor access to any of Etern’s technology, platforms or features.</p>
                            </li>
                            <li>
                                <p>You acknowledge that you are purchasing this Eternalz Council NFT for your own
                                    personal enjoyment without any expectation of profit, increase in monetary or other
                                    value, nor access to any of Etern’s technology, platforms or features.</p>
                            </li>
                            <li>
                                <p>You confirm that you are the rightful owner of your NFT and you acknowledge and
                                    agree that the intellectual property rights of your PFP NFT lies solely with you and that
                                    you have all necessary rights, permissions and consent to use your NFT as per the
                                    agreement and terms you have with the original NFT collection. You acknowledge that
                                    Etern does not assume any responsibility or liability for any intellectual property
                                    infringements or violations that may arise in connection to your use of your NFT. You
                                    agree that as the owner of the NFT you are providing permission for Etern to replicate
                                    and transform your NFT into a 4D avatar version for use in the Metaverse. As the sole
                                    owner of the NFT you agree to indemnify and hold Etern meta harmless from any
                                    damages, losses, liabilities, expenses or claims that may arise in connection with your
                                    use of your NFT.
                                </p>
                            </li>
                            <li>
                                <p>Following minting your Eternalz Council NFT, Etern undertakes the transformation of
                                    your 4D avatar as detailed in 4.3 above. Note that you will be able to claim your free
                                    4000 sqft Metaverse land during the future upcoming land mint of Etern Metaverse,
                                    the date of which will be announced by Etern in the future. Etern will inform you from
                                    time to time regarding the timeline in relation to claiming the other benefits associated
                                    with your NFT.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Following minting your Eternalz Council NFT, Etern undertakes the transformation of
                                    your 4D avatar as detailed in 4.3 above. Note that you will be able to claim your free
                                    4000 sqft Metaverse land during the future upcoming land mint of Etern Metaverse,
                                    the date of which will be announced by Etern in the future. Etern will inform you from
                                    time to time regarding the timeline in relation to claiming the other benefits associated
                                    with your NFT.

                                </p>
                            </li>
                            <li>
                                <p>
                                    Etern does not provide any guarantees related to the value or worth of the Eternalz
                                    Council NFT, 4D avatar, the free 4000 sqft land or any other rewards or benefits
                                    associated with minting of an Eternalz Council NFT. You acknowledge that the value
                                    of the NFT and associated benefits may fluctuate over time and that Etern does not
                                    guarantee any increase in value or profit over the long term. The Purchaser
                                    understands that the value of the NFT and associated benefits may depend on various
                                    factors outside the control of Etern. The Purchaser assumes all risks associated with
                                    purchasing and ownership of the NFT and agrees that Etern shall not be liable for any
                                    loss or damage arising from decrease in value or lack of profit associated with the NFT
                                    and related benefits.
                                </p>
                            </li>
                            <li>
                                <p>
                                    You may not use the EternalzCouncil NFT Mint for any illegal or unauthorized purpose.
                                    You may not violate any laws, rules, or regulations in your participation in the Mint, and
                                    you may not use the Eternalz Council Mint to infringe the intellectual property or other
                                    rights of any third party.

                                </p>
                            </li>
                            <li>
                                <p>
                                    Etern reserves the right to modify these Terms of Service at any time. Any changes to
                                    these terms will be effective immediately upon posting the modified Terms of Service
                                    on the Etern website. Your continued participation in the Eternalz Council Mint after
                                    any such modifications constitutes your acceptance of the modified terms.
                                </p>
                            </li>
                        </ol>
                    </ul>
                </div>

                <div>
                    <ul className='section-header-kps'>
                        <b>
                            <li>
                                <p>5. Warranty Disclaimers</p>
                            </li>
                        </b>
                    </ul>
                    <p>
                        THE ETERNALZ COUNCIL NFT AND ASSOCIATED SERVICES, BENEFITS AND
                        REWARDS ARE PROVIDED ‘AS IS’ WITHOUT WARRANTIES OF ANY KIND EITHER
                        EXPRESSED OR IMPLIED. ETERN DISCLAIMS ALL WARRANTIES, EXPRESSED OR
                        IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF
                        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. ETERN DOES NOT GUARANTEE THAT THE ETERNALZ COUNCIL NFT
                        MINT, THE MINTED NFTS OR ANY ASSOCIATED SERVICES AND BENEFITS WILL BE
                        UNINTERRUPTED OR ERROR-FREE. THE PURCHASER ASSUMES ALL RISK
                        ASSOCIATED WITH THE USE OF THE NFT AND ANY ASSOCIATED SERVICES AND
                        BENEFITS, INCLUDING, WITHOUT LIMITATION, ANY RISKS ASSOCIATED WITH YOUR
                        RELIANCE ON THE ACCURACY, COMPLETENESS, OR USEFULNESS OF THE NFT AND
                        ASSOCIATED SERVICES AND BENEFITS.
                    </p>
                    <p>
                        ETERN WILL NOT BE RESPONSIBLE OR LIABLE TO THE PURCHASER FOR ANY LOSS,
                        NOR LIABLE TO THE PURCHASER , TO THE FULLEST EXTENT OF THE LAW, ANY
                        LOSSES, DAMAGES OR CLAIMS ARISING FROM: 1) LOST SEED PHRASES OR
                        FORGOTTEN WALLET PASSWORDS 2) DATA LOSS OR SERVER / CONNECTION
                        FAILURES 3) ERRORS OR FAILURES OF THE BLOCKCHAIN TRANSACTION DURING
                        MINTING 4) THIRD PARTY UNAUTHORIZED ACCESS TO THE DATA STORED IN THE
                        WALLET 5) RISK OF MALICIOUS SOFTWARE INTRODUCTIONS 6) LOSSES DUE TO
                        FRAUDULENT OR ACCIDENTAL TRANSACTIONS 7)TAXES, GAS FEES OR ANY OTHER
                        FEES IN RELATION TO THE MINTING PROCESS 8) SOME TRANSACTIONS IN DIGITAL
                        ASSETS SHALL BE DEEMED TO BE MADE WHEN RECORDED ON A PUBLIC LEDGER,
                        WHICH IS NOT NECESSARILY THE DATE AND TIME YOU INITIATED THE
                        TRANSACTION AND 9) THAT ETERN MAY RELY ON 3RD PARTIES THAT ARE OUT OF
                        ETERN’S CONTROL IN CONNECTION WITH TRANSACTIONS RELATED TO THE NFTS.
                    </p>
                </div>

                <div>
                    <ul className='section-header-kps'>
                        <b>
                            <li>
                                <p>6. Indemnity</p>
                            </li>
                        </b>
                    </ul>
                    <p>
                        The Purchaser shall defend, indemnify and hold harmless Etern, its officers, directors,
                        employees, and agents from and against all claims, costs, expenses, liabilities and damages
                        (including reasonable attorney’s fees) arising from any breach of this agreement or by neglect
                        or willful act or omission of the Purchaser in relation to the purchase or ownership of the NFT.
                        This includes, but is not limited to, any claims of infringement of intellectual property rights
                        related to the NFT. The Purchaser’s obligations under this section shall survive the transfer of
                        ownership of the NFT.
                    </p>
                </div>

                <div>
                    <ul className='section-header-kps'>
                        <b>
                            <li>
                                <p>7. Limitation of Liability</p>
                            </li>
                        </b>
                    </ul>
                    <p>
                        In no event shall Etern be liable to the Purchaser for any special, indirect, incidental,
                        consequential, or punitive damages, including without limitation, loss of data, loss of profit or
                        use arising in connection with Agreement or the purchase, ownership or use of the NFT or
                        associated services and rewards, where such liability arises from any claim based upon
                        contract, warranty, negligence or otherwise, and whether you have been advised of the
                        possibility of such loss or damage even if a limited remedy set forth herein is found to have
                        failed of its essential purpose.
                    </p>
                </div>

                <div>
                    <ul className='section-header-kps'>
                        <b>
                            <li>
                                <p>8. Governing Law</p>
                            </li>
                        </b>
                    </ul>
                    <p>
                        All claims and disputes arising under or relating to this Agreement are to be settled by binding
                        arbitration in the state of Delaware or another location mutually agreeable to the parties.
                    </p>
                    <p>
                        This Agreement shall be governed by and construed in accordance with the laws of the State
                        of Delaware, without reference to conflicts of laws or choice of laws rules. All legal actions
                        relating to this Agreement shall be brought in the state or federal courts located in the State of
                        Delaware.

                    </p>
                </div>

                <div>
                    <ul className='section-header-kps'>
                        <b>
                            <li>
                                <p>Contact Information:</p>
                            </li>
                        </b>
                    </ul>
                    <p>
                        If you have any questions regarding this Agreement please contact DejaVu Quest, Inc. at
                        metaverse@dejavu.quest
                    </p>
                </div>


            </div>
        </div>
    );
}

export default Tos;