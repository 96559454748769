import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Nft from "./components/Dashboard";
import Login from "./components/Login";

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}>
          </Route>
          <Route path="/login" element={<Login />}>
          </Route>
           <Route path="/dashboard"  element={<Nft/>}>
          </Route>
          <Route path="*" element={<Home />} ></Route>
        </Routes>
      </BrowserRouter>
  );
}


export default App;