import React, { useState } from 'react';
import eternMeta from '../img/etern_main_logo.png';
import profileIcon from '../img/profile_icon.png';
import '../App.scss';
import toast from 'react-hot-toast';
import { ethers } from 'ethers';
const networkChainId = 1;
const wrongChainErrorMessage = "You need to change network to Ethereum Mainnet";

function NavHeader() {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    // eslint-disable-next-line
    const [currentAccount, setCurrentAccount] = useState(null);

// eslint-disable-next-line
    const handleMobileMenuToggle = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };


    const onClickConnect = async () => {
        const { ethereum } = window;
        try {
            await ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x' + networkChainId }]
            });
        } catch (switchError) {
            toast.error('Could not switch to Network ID: ' + networkChainId);
        }
    };

    const connectWalletHandler = async () => {
        const { ethereum } = window;

        if (!ethereum) {
            toast.error('Please install MetaMask!', { id: 'toast' });
        }

        try {
            if (!(await checkNetwork())) return;
            const accounts = await ethereum.request({
                method: 'eth_requestAccounts'
            });
            setCurrentAccount(accounts[0]);
            localStorage.setItem("wallet_address", accounts[0]);
            window.location.reload(false);
        } catch (err) {
            console.log(err);
        }
    };

    const checkNetwork = async () => {
        const { ethereum } = window;
        const provider = new ethers.providers.Web3Provider(ethereum);
        const { chainId } = await provider.getNetwork();

        if (chainId !== networkChainId) {
            toast(
                t => (
                    <span>
                        {wrongChainErrorMessage}
                        <button
                            onClick={onClickConnect}
                            className="btn wallet-connect"
                        >
                            Change
                        </button>
                    </span>
                ),
                { id: 'change-net' }
            );
            setCurrentAccount(null);

            return false;
        }
        return true;
    };

    return (
        <div>
            <nav className="nav-header">
                <div className='site-nav-container-bar-kps'>
                    <div className='nav-center-kps'>
                        <ul className="left-side">
                            <li>
                                <a href="https://www.eternmeta.com/" target="_blank" rel="noreferrer">
                                    <img src={eternMeta} alt="" />
                                </a>
                            </li>
                        </ul>
                        <ul className="center-side">
                            <li><a href="https://www.eternmeta.com/worldz" target="_blank" rel="noreferrer">WORLDZ</a></li>
                            <li><a href="https://www.eternmeta.com/utility" target="_blank" rel="noreferrer">UTILITY</a></li>
                            <li><a href="https://www.eternmeta.com/OGEternalz" target="_blank" rel="noreferrer">OG ETERNALZ</a></li>
                            <li><a href="https://www.eternmeta.com/about" target="_blank" rel="noreferrer">ABOUT</a></li>
                            <li><a href="https://www.eternmeta.com/referral_program" target="_blank" rel="noreferrer">AFFILIATES</a></li>
                        </ul>
                        <ul className="right-side">
                            <li>
                            { localStorage.getItem("wallet_address") ?
                                <a className='green-status' href="/#">
                                    <span className="status-connection"></span>
                                    <img className="icon-profile" src={profileIcon} alt="" />
                                    <p className='greenText'>Connected</p>
                                </a>
                            :
                                <a className='red-status' onClick={connectWalletHandler}  href="/#">
                                    <span className="status-connection"></span>
                                    <img className="icon-profile" src={profileIcon} alt="" />
                                    <p className='redText'>Connect</p>
                                </a>
                            }
                            </li>
                        </ul>
                    </div>
                    <div className="nav-center-kps mobile-nav-kps">
                        <ul>
                            <li>
                                <button type="button" className="mobile-menu-kps navbar-toggle x collapsed"
                                    data-toggle="collapse" data-target="#navbar-collapse-x">
                                    <span className="icon-burger-menu"></span>
                                </button>
                            </li>
                            <li>
                                <a href="https://www.eternmeta.com/" target="_blank" rel="noreferrer">
                                    <img src={eternMeta} alt="" />
                                </a>
                            </li>
                            <li className="btn-list-item-kps">
                                <a href="/#" className='green-status'>
                                    <span className="status-connection"></span>
                                    <img className="icon-profile" src={profileIcon} alt="" />
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>
            <div className="collapse navbar-collapse mobile-menu-drop-down-kps" id="navbar-collapse-x">
                <ul className="nav navbar-nav navbar-right">
                    <li data-toggle="collapse" data-target="#navbar-collapse.in">
                        <a className="active" href="https://www.eternmeta.com/worldz" target="_blank" rel="noreferrer">WORLDZ</a>
                    </li>
                    <li data-toggle="collapse" data-target="#navbar-collapse.in">
                        <a className="" href="https://www.eternmeta.com/utility" target="_blank" rel="noreferrer">UTILITY</a>
                    </li>
                    <li data-toggle="collapse" data-target="#navbar-collapse.in">
                        <a className="" href="https://www.eternmeta.com/OGEternalz" target="_blank" rel="noreferrer">OG ETERNALZ</a>
                    </li>
                    <li data-toggle="collapse" data-target="#navbar-collapse.in">
                        <a className="" href="https://www.eternmeta.com/about" target="_blank" rel="noreferrer">ABOUT</a>
                    </li>
                    <li data-toggle="collapse" data-target="#navbar-collapse.in">
                        <a className="" href="https://www.eternmeta.com/referral_program" target="_blank" rel="noreferrer">AFFILIATES</a>
                    </li>
                </ul>
            </div>
        </div>

    );
}

export default NavHeader;
