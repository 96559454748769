import React from 'react';
import '../App.scss';
import bulletPointIcon from '../img/bullet_point_icon.png';
import utilityRibbonOne from '../img/utility/ribbons/privilege_main_banner_blue.png';
import utilityImageOne from '../img/utility/etern_ape_bts_video_thumbnail.jpg';
import utilityVideoOne from '../videos/etern_ape_bts_video.mp4';
import utilityRibbonTwo from '../img/utility/ribbons/privilege_main_banner_2.png';
import utilityImageTwo from '../img/utility/section_graphic_2.jpg';
import utilityRibbonThree from '../img/utility/ribbons/privilege_main_banner_3.png';
import utilityImageThree from '../img/utility/section_graphic_3.jpg';
import utilityRibbonFour from '../img/utility/ribbons/privilege_main_banner_4.png';
import utilityImageFour from '../img/utility/section_graphic_6.jpg';
import utilityRibbonFive from '../img/utility/ribbons/privilege_main_banner_5.png';
import utilityImageFive from '../img/utility/section_graphic_7.jpg';
import utilityRibbonLast from '../img/utility/ribbons/grand_prize_banner.png';
import utilityImageLast from '../img/utility/section_graphic_8.jpg';



function Utility() {
    return (
        <div>
            <div className="body-container-kps mobile-pt-kps time-travel-video-section-kps council-text-bg-image">
                <div className="center-content-parallax-content-kps left-content-parallax-content-kps">
                    <h1 className="new-topic-kps">Special Privileges Reserved For Eternalz Council</h1>
                    <h3 className="small-topic-kps">(288 Members Only)</h3>
                    <div className="utility-card-container-kps">
                        <div className="utility-card-content full-utiliy-card">
                            <div className="utility-card-img-inner-kps">
                                <img src={utilityRibbonOne} alt="" />
                            </div>
                            <div className="utility-img-kps">
                                <video poster={utilityImageOne} muted loop autoPlay playsInline>
                                    <source src={utilityVideoOne} type="video/mp4" />
                                </video>

                            </div>
                            <div className="utility-card-details-kps">
                                <h2>Eternal Avatarz: Bring your PFP to life as a 4D Metaverse Identity
                                </h2>
                                <h4>Bring your precious PFP to life, by transforming it into a 4D,
                                    lifelike Metaverse Avatar, also known as your “Eternal”.</h4>
                                <ul>
                                    <li>
                                        <img className="bullet-point" src={bulletPointIcon} alt="" />
                                        <p>Handmade to perfection by professionals.</p>
                                    </li>
                                    <li>
                                        <img className="bullet-point" src={bulletPointIcon} alt="" />
                                        <p>Rigged, LIVE, 4D and Metaverse ready.</p>
                                    </li>
                                    <li>
                                        <img className="bullet-point" src={bulletPointIcon} alt="" />
                                        <p>Use in branding, marketing and as a digital twin.</p>
                                    </li>
                                    <li>
                                        <img className="bullet-point" src={bulletPointIcon} alt="" />
                                        <p>Unlock a new world of web3 and web2 utility.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="utility-card-content small-main-card">
                            <div className="utility-card-img-inner-kps small-card">
                                <img src={utilityRibbonTwo} alt="" />
                            </div>
                            <h2>Get 4000 sqft of Etern Landz</h2>
                            <img className="utility-card-img-kps top-video-kps" src={utilityImageTwo} alt="" />
                            <div className="utility-card-details-kps">
                                <h4>Claim free 4000 Sqft of multiplayer powered Etern Metaverse Landz
                                    ready to be colonized.</h4>
                            </div>
                        </div>
                        <div className="utility-card-content small-main-card">
                            <div className="utility-card-img-inner-kps small-card">
                                <img src={utilityRibbonThree} alt="" />
                            </div>
                            <h2>Become one of the OG LandLordz</h2>
                            <img className="utility-card-img-kps top-video-kps" src={utilityImageThree} alt="" />
                            <div className="utility-card-details-kps">
                                <h4>You will receive OG LandLord Status in the Eternalz Council
                                    representing your NFT collection.</h4>
                            </div>
                        </div>
                        <div className="utility-card-content small-main-card">
                            <div className="utility-card-img-inner-kps small-card">
                                <img src={utilityRibbonFour} alt="" />
                            </div>
                            <h2>Get access to an exclusive OG network</h2>
                            <img className="utility-card-img-kps top-video-kps" src={utilityImageFour} alt="" />
                            <div className="utility-card-details-kps">
                                <h4>Get insider access to Etern’s handpicked OG Network, a closed group
                                    of Metaverse investors, developers, founders and creators.</h4>
                            </div>
                        </div>
                        <div className="utility-card-content small-main-card">
                            <div className="utility-card-img-inner-kps small-card">
                                <img src={utilityRibbonFive} alt="" />
                            </div>
                            <h2>Claim custom resources to build Worldz</h2>
                            <img className="utility-card-img-kps top-video-kps" src={utilityImageFive} alt="" />
                            <div className="utility-card-details-kps">
                                <h4>Unlock a pool of Metaverse Dev Creditz, Rewardz and get exclusive
                                    members-only Dealz on resources.</h4>
                            </div>
                        </div>
                        <div className="utility-card-content full-utiliy-card gold-kps">
                            <div className="utility-card-img-inner-kps">
                                <img src={utilityRibbonLast} alt="" />
                            </div>
                            <div className="utility-img-kps">
                                <img className="utility-card-img-kps top-video-kps" src={utilityImageLast} alt="" />
                            </div>
                            <div className="utility-card-details-kps">
                                <h2 className="gold-kps">Stand a chance to win $100,000 Dev Creditz <br/>
                                    <span>(Non-Cashable)</span>
                                </h2>
                                <h4>One of the 288 Eternalz Council NFT holders will be
                                    awarded cashless Dev Creditz valued at USD 100,000 to launch their
                                    own 44,000 sqft Multiplayer Metaverse powered by Etern.
                                </h4>
                                <h4 className="send-h-four"><span>*</span>Subject to strict eligibility criteria and
                                    terms of service. Selection is merit based.</h4>
                                <ul>
                                    <li>
                                        <img className="bullet-point" src={bulletPointIcon} alt="" />
                                        <p>Dev Creditz can be used to build your own branded Metaverse.
                                        </p>
                                    </li>
                                    <li>
                                        <img className="bullet-point" src={bulletPointIcon} alt="" />
                                        <p>Winner gets free 44,000 sqft Etern Landz.</p>
                                    </li>
                                    <li>
                                        <img className="bullet-point" src={bulletPointIcon} alt="" />
                                        <p>Dev Creditz will be in the form of exclusive Web3/Metaverse
                                            services to build on Etern Metaverse specified Landz.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Utility;