import React from 'react';
import '../App.scss';
import backgroundBanner from '../img/vr_girl_banner.jpg';
import councilGraphic from '../img/utility/eternalz_council_graphic.png';
import eternMeta from '../img/etern_logo.png';
import arrow from '../img/down_arrow.png';
import devices from '../img/platform_logos_image.png';

function CommonHeader() {
    return (
        <div>
            <div className="fourth-section-container-kps common-colum-container unility-page">
                <div className="body-container-kps mid-video-content-kps mt-no-mb-only">
                    <div className="utility-bg-image-container">
                        <img className="utility-bg-image" src={backgroundBanner} alt="" />
                    </div>
                    <div className="mainLandingPageInner utility-top-banner-text-kps">
                        <div className="main-landing-page-container description-page-container">
                            <div className="center-tittles common-home-page-height">
                                <div className="ec-logo-div">
                                    <img className="ec-log-kps" src={eternMeta} alt="" />
                                    <img className="ec-log-kps council-image" src={councilGraphic} alt="" />
                                </div>

                                <h2 className="main-second-topic">288 OG PASSES</h2>
                                <h2 className="main-thrid-topic">FOR VR/ XR / SPATIAL COMPUTING/ AI / METAVERSE PIONEERS, BUILDERS, INVESTORS AND WEB3 LEADERS</h2>
                                <p>Welcome to the Eternalz Council: First-of-its-kind AI powered Spatial Computing collective by Etern. Limited to only 288 OG Members, selected by a meticulous admission process that includes meetings, applications and recommendations.</p>
                                <p>The council is exclusively gated for web3 investors, builders, leaders, and change-makers. Kudos to those who are selected or recommended - you're about to mint a piece of web3 history!</p>
                                <p>Your Eternal is calling. Let’s begin your Metaverse adventure.</p>
                                <div className="devices-flex-container">
                                    <img className="utility-bg-image" src={devices} alt="" />
                                </div>
                                <div className="packaged-box-kps">
                                    <a className='down-arrow' href='#MintSection'>
                                        <img className="utility-bg-image" src={arrow} alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CommonHeader;