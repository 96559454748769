import { useEffect, useState } from 'react';
import { Button, Modal } from "react-bootstrap";
import '../App.scss';
import abi from '../contracts/abi';
// eslint-disable-next-line
import { ethers, BigNumber } from 'ethers';
import toast from 'react-hot-toast';
import * as Constants from '../constants/config';
import serverConfig from '../config/backend-config.json';
import axios from 'axios';
import Tos from './Tos';
import logo from '../img/egg_icon_platinum.png';
import imageBG from '../img/platinum_texture.png';

const contractAddress = Constants.CONTRACT_ADDRESS;
const networkChainId = 1;
const wrongChainErrorMessage = "You need to change network to Ethereum Mainnet";

const MintWorld = () => {
    const mintTitles = ['Presale Mint', 'Public Mint'];
    const [currentAccount, setCurrentAccount] = useState(null);
    const [mintStatuses, setMintStatuses] = useState([false, false]);

    if (mintTitles.length !== mintStatuses.length) {
        throw new Error('Could not load Mint');
    }
    const numMints = mintTitles.length;
    const [quantities, setQuantities] = useState([1, 1]);
    const [tokenPrices, setTokenPrices] = useState([0, 0]);
    // eslint-disable-next-line
    const [discountedTokenPrice, setDiscountedTokenPrice] = useState(0);
    const [discountValue, setDiscountValue] = useState(0);
    const [tokenPricesString, setTokenPricesString] = useState([1, 1]);
    const [totalCosts, setTotalCosts] = useState([0, 0]);
    const [totalCostsString, setTotalCostsString] = useState([1, 1]);
    const [remainingMints, setRemainingMints] = useState([0, 0]);
    // eslint-disable-next-line
    const [totalSupply, setTotalSupply] = useState(0);
    const [maxSupply, setMaxSupply] = useState(0);
    const [promoCode, setPromoCode] = useState(1);
    const [showMe, setShowMe] = useState(false);
    // eslint-disable-next-line
    const [appliedCode, setAppliedCode] = useState('');
    const [promo_id, setPromoId] = useState('');
    const [parentCode, setParentCode] = useState(1);
    const [remainingSupply, setRemainingSupply] = useState(0);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [actualTotal, setActualTotal] = useState(0);

    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => {
        setShowModal(true);
    };

    const decrementCount = index => {
        const quantity = quantities[index];
        if (quantity > 1) {
            setQuantities(quantities.map((q, i) => (i === index ? q - 1 : q)));
            const newCost = tokenPrices[index].sub(discountValue).mul(
                ethers.BigNumber.from(quantity - 1)
            );
            setTotalCosts(
                totalCosts.map((c, i) => (i === index ? newCost : c))
            );
            setTotalCostsString(
                totalCostsString.map((c, i) =>
                    i === index ? ethers.utils.formatEther(newCost) : c
                )
            );
            if (discountValue) {
                setTotalDiscount(discountValue.mul(ethers.BigNumber.from(quantity - 1)));
            }
            setActualTotal(tokenPrices[index].mul(ethers.BigNumber.from(quantity - 1)));
        }
    };

    const incrementCount = index => {
        const quantity = quantities[index];
        if (quantity < remainingMints[index]) {
            setQuantities(quantities.map((q, i) => (i === index ? q + 1 : q)));
            const newCost = tokenPrices[index].sub(discountValue).mul(
                ethers.BigNumber.from(quantity + 1)
            );
            setTotalCosts(
                totalCosts.map((c, i) => (i === index ? newCost : c))
            );
            setTotalCostsString(
                totalCostsString.map((c, i) =>
                    i === index ? ethers.utils.formatEther(newCost) : c
                )
            );
            if (discountValue) {
                setTotalDiscount(discountValue.mul(ethers.BigNumber.from(quantity + 1)));
            }
            setActualTotal(tokenPrices[index].mul(ethers.BigNumber.from(quantity + 1)));
        }
    };

    const checkNetwork = async () => {
        const { ethereum } = window;
        const provider = new ethers.providers.Web3Provider(ethereum);
        const { chainId } = await provider.getNetwork();

        if (chainId !== networkChainId) {
            toast(
                t => (
                    <span>
                        {wrongChainErrorMessage}
                        <button
                            onClick={onClickConnect}
                            className="wallet-connect wallet-connect-kps"
                        >
                            Change
                        </button>
                    </span>
                ),
                { id: 'change-net' }
            );
            setCurrentAccount(null);

            return false;
        }
        return true;
    };

    const checkWalletIsConnected = async () => {
        const { ethereum } = window;

        if (!ethereum) {
            toast.error('Make sure you have MetaMask installed!', {
                id: 'toast'
            });
            return;
        } else {
            toast.success("Wallet connected. We're ready to go!", { id: 'toast' });
        }

        if (!(await checkNetwork())) return [null, false];
        const accounts = await ethereum.request({ method: 'eth_accounts' });

        if (accounts.length !== 0) {
            const account = accounts[0];
            // console.log('Found an authorized account: ', account);
            setCurrentAccount(account);
            return [account, true];
        } else {
            setCurrentAccount(null);
            toast.error('No authorized account found');
            return [null, true];
        }
    };

    const onClickConnect = async () => {
        const { ethereum } = window;
        try {
            await ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x' + networkChainId }]
            });
        } catch (switchError) {
            toast.error('Could not switch to Network ID: ' + networkChainId);
        }
    };

    const connectWalletHandler = async () => {
        const { ethereum } = window;

        if (!ethereum) {
            toast.error('Please install MetaMask!', { id: 'toast' });
        }

        try {
            if (!(await checkNetwork())) return;
            const accounts = await ethereum.request({
                method: 'eth_requestAccounts'
            });
            setCurrentAccount(accounts[0]);
            localStorage.setItem("wallet_address", accounts[0]);
            window.location.reload(false);
        } catch (err) {
            console.log(err);
        }
    };

    const promo = async () => {
        if (promoCode !== 1 && currentAccount) {
            try {
                const response = await axios.post(`${serverConfig.api}/promotion/validate`, {
                    promo_code: promoCode,
                    wallet_address: currentAccount
                });
                if (response.data.code) {
                    if (mintStatuses[0] && response.data.code === '2563') {
                        setAppliedCode(promoCode);
                        setParentCode(response.data.code);
                        setPromoId(response.data.promo_id);
                        const discountVal = tokenPrices[0].mul(80).div(100);
                        setDiscountValue(discountVal);

                        const discountedTokenPrice = tokenPrices[0].sub(discountVal)
                        setDiscountedTokenPrice(discountedTokenPrice);

                        const newCost = discountedTokenPrice.mul(ethers.BigNumber.from(quantities[0]));
                        setTotalDiscount(discountVal.mul(ethers.BigNumber.from(quantities[0])));
                        setActualTotal(tokenPrices[0].mul(ethers.BigNumber.from(quantities[0])));
                        setTotalCosts(
                            totalCosts.map((c, i) => (i === 0 ? newCost : c))
                        );
                        setTotalCostsString(
                            totalCostsString.map((c, i) =>
                                i === 0 ? ethers.utils.formatEther(newCost) : c
                            )
                        );
                        toast.success("Discount Code is Applied!", { duration: 4000, id: "promo-applied" });
                        setShowMe(true);
                    } else if (mintStatuses[1] && response.data.code === '1793') {
                        setAppliedCode(promoCode);
                        setParentCode(response.data.code);
                        setPromoId(response.data.promo_id);

                        const discountVal = tokenPrices[1].mul(80).div(100);
                        setDiscountValue(discountVal);

                        const discountedTokenPrice = tokenPrices[1].sub(discountVal)
                        setDiscountedTokenPrice(discountedTokenPrice);

                        const newCost = discountedTokenPrice.mul(ethers.BigNumber.from(quantities[1]));
                        setTotalDiscount(discountVal.mul(ethers.BigNumber.from(quantities[1])));
                        setActualTotal(tokenPrices[1].mul(ethers.BigNumber.from(quantities[1])));
                        setTotalCosts(
                            totalCosts.map((c, i) => (i === 1 ? newCost : c))
                        );

                        setTotalCostsString(
                            totalCostsString.map((c, i) =>
                                i === 1 ? ethers.utils.formatEther(newCost) : c
                            )
                        );
                        toast.success("Discount Code Applied!", { duration: 4000, id: "promo-applied" });
                        setShowMe(true);
                    } else {
                        setParentCode(1);
                        toast.error("Sorry, Invalid Discount Code!", { id: "promo-error" });
                        setShowMe(false);
                    }
                } else {
                    setParentCode(1);
                    setTotalCosts(
                        tokenPrices.map((p, i) =>
                            p.mul(ethers.BigNumber.from(quantities[i]))
                        )
                    );
                    toast.error(response.data.message, { id: "promo-error" })
                    setShowMe(false);
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    const connectWalletButton = () => {
        return (
            <button onClick={connectWalletHandler} className="kps-btn-css-componets fill-color-btn">
                <span>Connect Wallet</span>
            </button>
        );
    };

    const viewTrx = async url => {
        window.open(url, '_blank');
    };


    const mintToken = async saleIndex => {
        try {
            if (saleIndex >= numMints) {
                throw new Error('saleIndex >= numMints');
            }

            const { ethereum } = window;
            if (!ethereum) {
                toast.error('Please install MetaMask to continue', {
                    duration: 60000
                });
                return;
            }

            const provider = new ethers.providers.Web3Provider(ethereum);
            if (!provider) {
                toast.error('Please install MetaMask to continue', {
                    duration: 60000
                });
                return;
            }

            if (!currentAccount) {
                toast.error('Please connect your wallet to continue', {
                    duration: 60000
                });
                return;
            }

            const signer = provider.getSigner();
            const nftContract = new ethers.Contract(contractAddress, abi, signer);
            const totalCost = totalCosts[saleIndex];
            const quantity = quantities[saleIndex];

            let nftTxn;
            switch (saleIndex) {
                case 0:
                    nftTxn = nftContract.publicSaleMint(quantity, parentCode, {
                        value: totalCost
                    });
                    break;
                case 1:
                    nftTxn = nftContract.publicSaleMint(quantity, parentCode, {
                        value: totalCost
                    });
                    break;
                default:
                    throw new Error('saleIndex >= numMints');
            }
            nftTxn = await nftTxn;
            toast(
                t => (
                    <span>
                        Minting in progress...
                        <button
                            onClick={() =>
                                viewTrx(`${Constants.ETHERSCAN}/tx/${nftTxn.hash}`)
                            }
                            className="wallet-connect-kps"
                        >
                            View Tx
                        </button>
                    </span>
                ),
                { id: 'view-tx', duration: 30000 }
            );
            nftTxn = await nftTxn.wait();
            if (nftTxn.status === 1) {
                if (currentAccount && promo_id) {
                    try {
                        // eslint-disable-next-line
                        const response = await axios.post(`${serverConfig.api}/mint`, {
                            nft_count: quantity,
                            wallet_address: currentAccount,
                            promo_code_id: promo_id
                        });
                    } catch (error) {
                        console.log(error)
                    }
                }
                toast.success('NFT mint successful!', { duration: 15000 });
            } else {
                toast.error('NFT mint failed.', { duration: 15000 });
            }
            setTimeout(() => {
                window.location.reload(false);
            }, 2000);
        } catch (err) {
            if (err.error.message.includes('err: insufficient funds for gas')) {
                toast.error('Your Metamask wallet balance is not enough to complete this transaction.', { duration: 15000 });
            } else {
                toast.error(err.error.message.substr(19), { id: "toast" })
            }
        }
    };

    useEffect(() => {
        checkWalletIsConnected().then(([account, onCorrectNetwork]) =>
            getConfig(account, onCorrectNetwork)
        );
        document.cookie = `referral_key=hello;max-age=604800`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getConfig = async (currentAccount, onCorrectNetwork) => {
        try {
            if (!onCorrectNetwork) return;
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(contractAddress, abi, provider);
            let info;
            if (currentAccount) {
                info = await contract.getUserMintInfo(currentAccount);
                const maxPublicSaleMintAmount = info.maxMintInfo.maxPublicSaleMintAmount.toNumber();

                const publicSaleMinted = info.mintInfo.publicSaleMinted.toNumber();
                const publicSaleRemaining = Math.max(maxPublicSaleMintAmount - publicSaleMinted, 0);

                setRemainingMints([
                    publicSaleRemaining,
                    publicSaleRemaining
                ]);
            } else {
                info = await contract.getMintInfo();
            }

            setTotalSupply(info._totalSupply.toString());
            setMaxSupply(info._maxSupply.toString());
            setRemainingSupply((info._totalSupply.toNumber()) + (info._airdropSupply.toNumber()));
            setMintStatuses([
                info.saleInfo.preSaleActive,
                info.saleInfo.publicSaleActive
            ]);
            const tokenPrices = [
                info.priceInfo.publicSalePrice,
                info.priceInfo.publicSalePrice
            ];
            setTokenPrices(tokenPrices);
            setTokenPricesString([
                ethers.utils.formatEther(info.priceInfo.publicSalePrice),
                ethers.utils.formatEther(info.priceInfo.publicSalePrice)
            ]);
            setTotalCosts(
                tokenPrices.map((p, i) =>
                    p.mul(ethers.BigNumber.from(quantities[i]))
                )
            );
            setTotalCostsString(
                tokenPrices.map((p, i) =>
                    ethers.utils.formatEther(
                        p.mul(ethers.BigNumber.from(quantities[i]))
                    )
                )
            );

        } catch (error) {
            console.error(error);
        }
    };

    return (
        <section className="right-side-conatiner">
            {/* <div className='promoBox-header'>
                <h2 className='promo-box-title'>Eternalz Council</h2>
                <div className='sub-box-title'> <div className='live-dot'></div> LIVE MINT</div>
            </div> */}
            <div className="details-conatiner packaged-box">
                <div className="promo-box-contents packaged-value-inner-box">
                    <div className="promo-box-container b-card">
                        <div className='apply-code-kps'

                        >
                            <div className='save-price-section'>
                                <div className='top-logo-and-text'>
                                    <div className='top-logo'>
                                        <img className="top-logo-img" src={logo} alt="" />
                                    </div>
                                    <div className='top-text'>
                                        <h2>Create Your Own Digital Identity</h2>
                                    </div>
                                </div>
                                <div className='main-card-body'>
                                    <img className="top-logo-img-bg" src={imageBG} alt="" />
                                    <div className='flex-save-price-section'>
                                        <div className='left-side'>
                                            <h3>Packaged Value: </h3>
                                            <h3>Sponsorship by Etern Meta:</h3>
                                            <h3>Mint Value (80% Discount):</h3>
                                        </div>
                                        <div className='right-side'>
                                            <h3 className='wasprice'><s><span className='blacktext'>2 ETH</span></s></h3>
                                            <h3 className='price'><span className='blacktext'>1.6 ETH <span className='small-text'>*Limited Slots</span></span></h3>
                                            <h3 className='saveprice'><span className='greentext'>0.4 ETH + gas</span></h3>
                                        </div>
                                    </div>
                                    <div className='mobile-view-side'>
                                        <div className='left-side'>
                                            <h3>Packaged Value: <s><span className='blacktext'>24 ETH</span></s></h3>
                                            <h3>Sponsorship by Etern Meta: <span className='blacktext'>22 ETH <span className='small-text'>*Limited Slots</span></span></h3>
                                            <h3>Mint Value (90% Discount): <span className='greentext'>2 ETH + gas</span></h3>
                                        </div>
                                    </div>
                                </div>
                                {/* <p className="invite-text">(By Invitation Only)</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="details-conatiner">
                <div className="promo-box-contents">
                    <div className="promo-box-container box-height">
                        {currentAccount ?
                            <div className='promo-box'>
                                <div className="promo-inner-box">
                                    <label className="pro-lable">
                                        Apply Your Discount Code
                                    </label>
                                    <div className="two-item-conatiner mobile-center-two-item-conatiner">
                                        <input className="kps-input-form" type="text" placeholder="XXXXXX" onChange={(e) => setPromoCode(e.target.value)} />
                                        <button type="button" className="kps-btn-css-componets apply-btn-style two-col-btn" onClick={promo}>
                                            Apply
                                        </button>
                                    </div>
                                </div>
                                <div className='apply-code-kps'
                                    style={{
                                        display: showMe ? "none" : "block"
                                    }}
                                >
                                    <p className='withot-promocode'>Enter your invitee-only Presale Code. Then click ‘Apply’ to get the 80% discount to mint at 0.4ETH.</p>
                                </div>
                                <div className='apply-code-kps'
                                    style={{
                                        display: showMe ? "block" : "none"
                                    }}
                                >
                                    <p>Congratulations! Your invitation code is eligible for the 80% discount on the public mint price.</p>
                                </div>
                            </div>

                            :
                            <div className='promo-box'>
                                <div className='overlay-promo-box'>
                                    <div className='step-list'>
                                        <div className='dot-kps'></div>
                                        <p className='withot-promocode'>Step 1: <span className='no-b'>Connect Your MetaMask</span></p>
                                    </div>
                                    <div className='step-list'>
                                        <div className='dot-kps'></div>
                                        <p className='withot-promocode'>Step 2: <span className='no-b'>Enter Your Discount Code</span></p>
                                    </div>
                                    <div className='step-list'>
                                        <div className='dot-kps'></div>
                                        <p className='withot-promocode'>Step 3: <span className='no-b'>Unlock 80% Discount</span></p>
                                    </div>
                                </div>

                                <div className="promo-inner-box">
                                    <label className="pro-lable">
                                        Apply Your Discount Code
                                    </label>
                                    <div className="two-item-conatiner mobile-center-two-item-conatiner ">
                                        <input title="Connect wallet to enter the Discount Code." className="kps-input-form kps-disable" disabled type="text" placeholder="XXXXXX" />
                                        <button type="button" disabled className="kps-btn-css-componets apply-btn-style two-col-btn">
                                            Apply
                                        </button>
                                    </div>

                                </div>
                            </div>
                        }
                        {/* <div className='promo-box'>
                            <div className="promo-inner-box">
                                <label className="pro-lable">
                                    Enter your Discount Code (PPC)
                                </label>
                                <div className="two-item-conatiner mobile-center-two-item-conatiner">
                                    <input className="kps-input-form" type="text" placeholder="XXXXXX" onChange={(e) => setPromoCode(e.target.value)} />
                                    <button type="button" className="kps-btn-css-componets apply-btn-style two-col-btn" onClick={promo}>
                                        Apply
                                    </button>
                                </div>
                            </div>
                            <div className='apply-code-kps'
                                style={{
                                    display: showMe ? "none" : "block"
                                }}
                            >
                                <p className='withot-promocode'>Enter your invitee-only Presale Code to get the 80% discount to mint Eternalz Council at 0.4 ETH.</p>
                            </div>
                            <div className='apply-code-kps'
                                style={{
                                    display: showMe ? "block" : "none"
                                }}
                            >
                                <p>Congratulations! Your invitation code is eligible for the 80% discount on the public mint price.</p>
                            </div>
                        </div> */}

                        <div className="text-conatiner-kps">

                            {/* {currentAccount ?

                                <div className='top-count-box'>
                                    <div className="frist-text">
                                        <h2>Supply</h2>
                                    </div>
                                    <h3 className="count">
                                        {remainingSupply}/{maxSupply}
                                    </h3>
                                </div>

                                :
                                <div className='top-count-box'>
                                    <div className="frist-text">
                                        <h2>Supply</h2>
                                    </div>
                                    <h3 className="count">
                                        288
                                    </h3>
                                </div>
                            } */}

                            {mintTitles.map((title, index) => {
                                if (!currentAccount) return null;
                                if (!mintStatuses[index]) {
                                    return (
                                        <div key={index}>
                                            <div className="warning">
                                                <p>

                                                </p>
                                            </div>
                                        </div>
                                    );
                                }
                                const cost = tokenPricesString[index];
                                const quantity = quantities[index];
                                const mintsRemaining = remainingMints[index];
                                const totalCost = totalCostsString[index];
                                if (mintsRemaining <= 0) {
                                    return (
                                        <div key={index}>
                                            <div className="warning-container">
                                                <p>
                                                    You have 0 mints remaining for {title}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                }


                                return (
                                    <div key={index}>

                                        <div className='top-border-kps no-border'>
                                            <div className="qty-main-container">
                                                <div className="qty-inner-container">
                                                    <button
                                                        className="btn minus"
                                                        onClick={() =>
                                                            decrementCount(
                                                                index
                                                            )
                                                        }
                                                    >
                                                        -
                                                    </button>
                                                    <div className="count-container">
                                                        <h2>{quantity}</h2>
                                                    </div>

                                                    <button
                                                        className="btn plus"
                                                        onClick={() =>
                                                            incrementCount(
                                                                index
                                                            )
                                                        }
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        {discountedTokenPrice ?
                                            <div className="sale-conatiner thing-top-border-kps">
                                                <div className="sale-conatiner-inner">
                                                    Original Price
                                                </div>
                                                <div className="sale-conatiner-count">
                                                    <h3>
                                                        <span>{ethers.utils.formatEther(actualTotal)}</span> ETH
                                                    </h3>
                                                </div>
                                            </div>
                                            :

                                            <div className="sale-conatiner thing-top-border-kps">
                                                <div className="sale-conatiner-inner">
                                                    Regular Mint Price
                                                </div>
                                                <div className="sale-conatiner-count">
                                                    <h3>
                                                        <span>{cost}</span> ETH
                                                    </h3>
                                                </div>
                                            </div>
                                        }

                                        {discountedTokenPrice ?
                                            <div className="sale-conatiner thing-top-border-kps">
                                                <div className="sale-conatiner-inner ppc-code-kps">
                                                    Discount (80% off)
                                                </div>
                                                <div className="sale-conatiner-count">
                                                    <h3 className='ppc-code-kps'>
                                                        <span>- {ethers.utils.formatEther(totalDiscount)}</span> ETH
                                                    </h3>
                                                </div>
                                            </div>
                                            : ''}

                                        <div className="sale-conatiner bold-border-kps">
                                            <div className="sale-conatiner-inner total-count">
                                                Total
                                            </div>
                                            <div className="sale-conatiner-count ">
                                                <h3 className='total-count'>
                                                    <span>{totalCost}</span> ETH + gas
                                                </h3>
                                            </div>
                                        </div>

                                        <div className='toslinkkps'>
                                            <input type="hidden" checked={isChecked} onChange={handleCheckboxChange} />
                                            <label>
                                            Each Eternalz NFT you mint will represent an exclusive pass to the Eternalz Council.
                                            </label>
                                            <Modal className='tosModel' show={showModal} onHide={() => setShowModal(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title></Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <Tos />
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                                                        Close
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </div>

                                            <button
                                                className="kps-btn-css-componets tb-btn-kps round-btn-kps fill-color-btn"
                                                onClick={() =>
                                                    mintToken(index)
                                                }
                                            >
                                                <span>
                                                    Mint Now
                                                </span>
                                            </button>

                                        <br />
                                    </div>
                                );
                            })}

                            {currentAccount ? '' : connectWalletButton()}
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}
export default MintWorld;
